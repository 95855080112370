import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TemporaryDrawer from "Drawers/AdminDrawer.js";
import APIRequest from "Helpers/ApiRequest.js";
import ClearSession from "Helpers/ClearSession.js";
import theme from "Helpers/theme.js";
import conectadoIcon from "img/ConectadoIcon.png";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Moment from "react-moment";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, email, status, account_id) {
  return { name, email, status, account_id };
}

function encodeURI(details) {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

const defaultTheme = theme;
const fileTypes = ["JPG", "PNG"];
const cookies = new Cookies();

async function getData() {
  try {
    var newRows = [];
    var emails = [];
    var facultyBody = encodeURI({ action: "admin_list_faculty" });
    var adminBody = encodeURI({ action: "admin_list_admins" });

    const response = await Promise.all([
      fetch("https://conectadoverse.com/api/api.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: facultyBody,
      }),
      fetch("https://conectadoverse.com/api/api.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: adminBody,
      }),
    ]);
    let facultyData = await response[0].json();
    let adminData = await response[1].json();

    if (adminData.status == false && facultyData.status == false) {
      console.log("Failed");
      if (location.hostname !== "localhost") {
        ClearSession();
      }
    }

    if (adminData.status == true) {
      adminData.data.admins.forEach((fac) => {
        newRows.push(
          createData(`${fac.firstname} ${fac.lastname}`, fac.email, 1, fac.id)
        );
        emails.push(fac.email);
      });
    }
    if (facultyData.status == true) {
      facultyData.data.faculty.forEach((fac) => {
        if (!emails.includes(fac.email)) {
          newRows.push(
            createData(`${fac.firstname} ${fac.lastname}`, fac.email, 0, fac.id)
          );
        }
      });
    }
  } catch (err) {
    setError("error");
    setMessage(err);
    console.log(err);
  } finally {
    return newRows;
  }
}

async function getCurrentUsers() {
  try {
    var userCountTemp = "Error";
    var callBody = encodeURI({ action: "get_server_sessions" });
    const response = await Promise.all([
      fetch("https://conectadoverse.com/api/api.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: callBody,
      }),
    ]);
    let callData = await response[0].json();

    if (callData.status == true) {
      userCountTemp = callData.data.sessions.length;
    }
  } catch (err) {
    setError("error");
    setMessage(err);
    console.log(err);
  } finally {
    return userCountTemp;
  }
}

function createLogData(firstname, lastname, ipaddress, source, logintime) {
  return { firstname, lastname, ipaddress, source, logintime };
}

function createActiveData(firstname, lastname, time_created, account_id) {
  return { firstname, lastname, time_created, account_id };
}

function createBanData(
  account_id,
  firstname,
  lastname,
  email,
  reason,
  time,
  issuedby_id,
  issuedby_name
) {
  return {
    account_id,
    firstname,
    lastname,
    email,
    reason,
    time,
    issuedby_id,
    issuedby_name,
  };
}

export default function Admin() {
  const [page, setPage] = React.useState(0);
  const [pageCache, setPageCache] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState("");
  const [userCount, setUserCount] = React.useState("0");
  const [isAdmin, setIsAdmin] = React.useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [adminLogs, setAdminLogs] = React.useState("");
  const [activityLink, setActivityLink] = React.useState("");
  const [banRows, setBanRows] = React.useState([]);
  const [currentBans, setCurrentBans] = React.useState("");
  const [logRows, setLogRows] = React.useState([]);
  const [activeRows, setActiveRows] = React.useState([]);

  async function getActivityLink() {
    var req = await APIRequest({
      action: "server_settings_get",
      settings_name: "zoom_link",
    });
    if (req.status == true) {
      setActivityLink(req.data.settings_value);
    } else {
      handleOpen("error", req.message);
    }
  }

  async function getNewRowsAsync() {
    const row = await getData();
    setRows(row.sort((a, b) => (b.status < a.status ? -1 : 1)));
    setPage(pageCache);
  }

  async function getLogRowsAsync(email = false, all = false) {
    const row = await RetrieveLogs(email, all);
    setLogRows(row);
  }

  async function getActiveRowsAsync(email = false) {
    const row = await RetrieveActive(email);
    setActiveRows(row);
  }

  async function getBanRowsAsync() {
    const row = await RetrieveBans();
    setBanRows(row);
  }

  async function getNewUserCountAsync() {
    getActiveRowsAsync();
    const users = await getCurrentUsers();
    setUserCount(users);
  }

  React.useEffect(() => {
    async function getNewRows() {
      const row = await getData();
      setRows(row.sort((a, b) => (b.status < a.status ? -1 : 1)));
    }
    getNewRows();

    async function getNewUserCount() {
      const users = await getCurrentUsers();
      setUserCount(users);
    }
    getNewUserCount();

    getActivityLink();

    let timer;
    timer = setInterval(() => {
      const sec = new Date().getSeconds();

      if (sec) return;
      getNewUserCount();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageCache(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [err, setError] = React.useState("");
  const [mess, setMessage] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [facultyEmail, setFacultyEmail] = React.useState("");
  const [adminEmail, setAdminEmail] = React.useState("");
  const [validZoomLink, setValidZoomLink] = React.useState("");
  const [zoomLink, setZoomLink] = React.useState("");
  const [banPlayer, setBanPlayer] = React.useState("");
  const [logPlayer, setLogPlayer] = React.useState("");
  const [emailPlayer, setEmailPlayer] = React.useState("");
  const [emailPassword, setEmailPassword] = React.useState("");
  const [banReason, setBanReason] = React.useState("");
  const [logs, setLogs] = React.useState("");
  const [state, setState] = React.useState({
    settings: [
      { id: "zoom", open: false },
      { id: "email", open: false },
      { id: "management", open: true },
      { id: "logs", open: false },
      { id: "admin", open: false },
      { id: "currentbans", open: false },
      { id: "password", open: false },
      { id: "active", open: false },
    ],
  });

  const handleClick = (id) => {
    setState((state) => ({
      ...state,
      settings: state.settings.map((item) =>
        item.id === id ? { ...item, open: !item.open } : item
      ),
    }));
  };

  const handleOpen = (severity, message) => {
    const severities = ["error", "warning", "info", "success"];
    if (!severities.includes(severity)) {
      severity = "warning";
    }
    enqueueSnackbar(message, { variant: severity, preventDuplicate: true });
  };

  const handleZoomChange = (e) => {
    const enteredLink = e.target.value;
    const zoomRegex = /zoom./;
    setZoomLink(enteredLink);
    setValidZoomLink(zoomRegex.test(enteredLink));
  };

  async function ZoomLinkChange() {
    var req = await APIRequest({
      action: "server_settings_set",
      settings_name: "zoom_link",
      settings_value: zoomLink,
    });
    if (req.status == true) {
      handleOpen("success", req.message);
    } else {
      handleOpen("error", req.message);
    }
  }

  async function sendPasswordReset() {
    var req = await APIRequest({
      action: "password_forget",
      email: emailPassword,
    });
    if (req.status == true) {
      handleOpen("success", req.message);
    } else {
      handleOpen("error", req.message);
    }
  }

  async function RetrieveLogs(email = false, all = false) {
    console.log("here");
    var newRows = [];
    var req = await APIRequest({
      action: "get_login_log",
      ...(emailPlayer.length > 0 && email && !all
        ? { email: emailPlayer }
        : {}),
      ...(logPlayer.length > 0 && !email && !all
        ? { account_id: logPlayer }
        : {}),
    });
    if (req.status == true) {
      req.data.logins.forEach((element) => {
        newRows.push(
          createLogData(
            element.firstname,
            element.lastname,
            element.ipaddress,
            element.source,
            element.login_time
          )
        );
      });
    } else {
      handleOpen("error", req.message);
    }
    return newRows;
  }

  async function RetrieveActive() {
    var newRows = [];
    var req = await APIRequest({
      action: "get_server_sessions",
    });
    if (req.status == true) {
      req.data.sessions.forEach((element) => {
        newRows.push(
          createActiveData(
            element.firstname,
            element.lastname,
            element.time_created,
            element.id
          )
        );
      });
      if (req.data.sessions.length == 0) {
        newRows.push(createActiveData("No Active Users", "", Date.now(), ""));
      }
    } else {
      handleOpen("error", req.message);
    }
    return newRows;
  }

  async function RetrieveBans() {
    console.log("here");
    var newRows = [];

    var req = await APIRequest({
      action: "admin_list_bans",
    });
    if (req.status == true) {
      req.data.bans.forEach((element) => {
        newRows.push(
          createBanData(
            element.account_id,
            element.firstname,
            element.lastname,
            element.email,
            element.reason,
            element.time_created,
            element.issuedby_id,
            `${element.issuer[0].firstname} ${element.issuer[0].lastname}`
          )
        );
      });
    } else {
      handleOpen("error", req.message);
    }
    return newRows;
  }

  const [file, setFile] = React.useState(null);
  const handleChange = (file) => {
    setFile(file);
    console.log(file);
  };

  const areAllFieldsFilled = file != null;

  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const handleUserRemove = async (event, statusIn, emailIn) => {
    event.preventDefault();
    switch (statusIn) {
      case 0:
        //Remove Faculty
        var body = await APIRequest({
          action: "admin_set_faculty",
          email: emailIn,
          value: 0,
        });
        break;

      case 1:
        //Remove Admin
        var body = await APIRequest({
          action: "admin_set_faculty",
          email: emailIn,
          value: 0,
        });
        var body2 = await APIRequest({
          action: "admin_set_admin",
          email: emailIn,
          value: 0,
        });
        break;
    }
    if (body.status == true) {
      handleOpen("success", body.message);
    } else {
      handleOpen("error", body.message);
    }
    getNewRowsAsync();
  };

  const handleUserPromoteDemote = async (event, statusIn, emailIn) => {
    event.preventDefault();
    switch (statusIn) {
      case 0:
        //promote faculty to admin
        var body = await APIRequest({
          action: "admin_set_admin",
          email: emailIn,
          value: 1,
        });
        break;

      case 1:
        //Demote admin to faculty
        var body2 = await APIRequest({
          action: "admin_set_faculty",
          email: emailIn,
          value: 1,
        });
        var body = await APIRequest({
          action: "admin_set_admin",
          email: emailIn,
          value: 0,
        });
        break;
    }
    if (body.status == true) {
      handleOpen("success", body.message);
    } else {
      handleOpen("error", body.message);
    }
    getNewRowsAsync();
  };

  const addUserType = async (event, action, email) => {
    event.preventDefault();
    var body = await APIRequest({ action: action, email: email, value: 1 });
    if (body.status == true) {
      handleOpen("success", "Added User");
    } else {
      handleOpen("error", body.message);
    }
    getNewRowsAsync();
  };

  const handleBanPlayer = async (event) => {
    event.preventDefault();
    var body = await APIRequest({
      action: "admin_ban_player",
      reason: banReason,
      ...(banPlayer.includes("@")
        ? { email: banPlayer }
        : { account_id: banPlayer }),
    });
    if (body.status == true) {
      handleOpen("success", "Banned User");
    } else {
      handleOpen("error", body.message);
    }
  };

  const handleUnbanPlayer = async (event) => {
    event.preventDefault();
    var body = await APIRequest({
      action: "admin_unban_player",
      ...(banPlayer.includes("@")
        ? { email: banPlayer }
        : { account_id: banPlayer }),
    });
    if (body.status == true) {
      handleOpen("success", "User has been Unbanned");
    } else {
      handleOpen("error", body.message);
    }
  };


  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <Box>
      <TemporaryDrawer />
      <Container component="main" maxWidth="xm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "6rem",
              height: "6rem",
              marginBottom: "1rem",
              boxShadow: "5px 10px 20px #0000000F",
              borderRadius: "15px",
            }}
          >
            <div
              alt="Conectado"
              style={{
                backgroundImage: `url(${conectadoIcon})`,
                width: "4rem",
                height: "4rem",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>
          <Typography component="h1" variant="h5">
            Admin
          </Typography>

          <Box
            component="form"
            id="loginForm"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                component="h3"
                variant="h7"
                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                Active Users: {userCount}
                <IconButton
                  variant="text"
                  onClick={() => handleClick("active")}
                >
                  {state.settings.find((item) => item.id === "active").open ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Typography>
              <IconButton
                aria-label="refreshusers"
                color="primary"
                onClick={getNewUserCountAsync}
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
            <Collapse
              in={state.settings.find((item) => item.id === "active").open}
              timeout="auto"
              unmountOnExit
            >
              {activeRows.length > 0 && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="Active Table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">ID</TableCell>
                        <TableCell align="right">Time Created</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeRows.map((row) => (
                        <TableRow key={row.account_id}>
                          <TableCell component="th" scope="row">
                            <div onClick={() => handleCopyClick(row.account_id)}>
                              {row.firstname}
                            </div>
                          </TableCell>
                          <TableCell align="right">{row.lastname}</TableCell>
                          <TableCell align="right">{row.account_id}</TableCell>
                          <TableCell align="right">
                            <Moment format="YYYY/MM/DD HH:mm">
                              {row.time_created}
                            </Moment>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Collapse>

            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h3"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Admin
              <IconButton variant="text" onClick={() => handleClick("admin")}>
                {state.settings.find((item) => item.id === "admin").open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "admin").open}
              timeout="auto"
              unmountOnExit
            >
              <Typography component="h3" variant="h6">
                Ban/Unban Player
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="banplayer"
                label="Player Email or ID"
                name="banplayer"
                value={banPlayer}
                onChange={(e) => setBanPlayer(e.target.value)}
                autoComplete="playeremail"
                autoFocus
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    borderRadius: "15px",
                    "& input": { paddingLeft: 2 },
                    "& .MuiFilledInput-input:autofill": {
                      boxShadow: 0,
                      borderRadius: 0,
                    },
                  },
                }}
              />
              <TextField
                margin="normal"
                fullWidth
                id="banreason"
                label="Ban Reason (Optional)"
                name="banreason"
                value={banReason}
                onChange={(e) => setBanReason(e.target.value)}
                autoComplete="reason"
                autoFocus
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    borderRadius: "15px",
                    "& input": { paddingLeft: 2 },
                    "& .MuiFilledInput-input:autofill": {
                      boxShadow: 0,
                      borderRadius: 0,
                    },
                  },
                }}
              />
              <Button
                type="adminSubmit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleBanPlayer}
                sx={{ mt: 1, mb: 2, mr: 2 }}
              >
                Ban
              </Button>
              <Button
                type="adminSubmit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={handleUnbanPlayer}
                sx={{ mt: 1, mb: 2 }}
              >
                Unban
              </Button>
              <Typography
                component="h3"
                variant="h7"
                sx={{ marginLeft: "1rem" }}
              >
                Current Bans
                <IconButton
                  type="adminSubmit"
                  variant="contained"
                  onClick={getBanRowsAsync}
                >
                  <RefreshIcon />
                </IconButton>
                <IconButton
                  variant="text"
                  onClick={() => handleClick("currentbans")}
                >
                  {state.settings.find((item) => item.id === "currentbans")
                    .open ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Typography>
              <Collapse
                in={
                  state.settings.find((item) => item.id === "currentbans").open
                }
                timeout="auto"
                unmountOnExit
                sx={{ marginLeft: "1rem" }}
              >
                {banRows.length > 0 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="Bans Table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell align="left">First Name</TableCell>
                          <TableCell align="left">Last Name</TableCell>
                          <TableCell align="right">Reason</TableCell>
                          <TableCell align="right">Time</TableCell>
                          <TableCell align="right">Issued By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {banRows.map((row) => (
                          <TableRow key={row.account_id}>
                            <TableCell component="th" scope="row">
                              {row.email}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.firstname}
                            </TableCell>
                            <TableCell align="left">{row.lastname}</TableCell>
                            <TableCell align="right">{row.reason}</TableCell>
                            <TableCell align="right">
                              <Moment format="YYYY/MM/DD HH:mm">
                                {row.time * 1000}
                              </Moment>
                            </TableCell>
                            <TableCell align="right">
                              {row.issuedby_name}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Collapse>
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h3"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Login Logs
              <IconButton variant="text" onClick={() => handleClick("logs")}>
                {state.settings.find((item) => item.id === "logs").open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "logs").open}
              timeout="auto"
              unmountOnExit
            >
              <div className="flex flex-row flex-wrap gap-4">
                <TextField
                  margin="normal"
                  id="logplayer"
                  label="Account ID (Optional)"
                  name="logplayer"
                  className="flex-auto"
                  value={logPlayer}
                  onChange={(e) => setLogPlayer(e.target.value)}
                  autoComplete="account"
                  autoFocus
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: "15px",
                      "& input": { paddingLeft: 2 },
                      "& .MuiFilledInput-input:autofill": {
                        boxShadow: 0,
                        borderRadius: 0,
                      },
                    },
                  }}
                />
                <Button
                  className=""
                  type="adminSubmit"
                  variant="contained"
                  onClick={getLogRowsAsync}
                  sx={{ mt: 1, mb: 2, mr: 2 }}
                >
                  <ArrowForwardIcon />
                </Button>
              </div>
              <div className="flex flex-row flex-wrap gap-4">
                <TextField
                  margin="normal"
                  id="logplayeremail"
                  label="Email (Optional)"
                  name="logplayeremail"
                  className="flex-auto"
                  value={emailPlayer}
                  onChange={(e) => setEmailPlayer(e.target.value)}
                  autoComplete="playeremail"
                  autoFocus
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: "15px",
                      "& input": { paddingLeft: 2 },
                      "& .MuiFilledInput-input:autofill": {
                        boxShadow: 0,
                        borderRadius: 0,
                      },
                    },
                  }}
                />
                <Button
                  className=""
                  type="adminSubmit"
                  variant="contained"
                  onClick={() => getLogRowsAsync(true)}
                  sx={{ mt: 1, mb: 2, mr: 2 }}
                >
                  <ArrowForwardIcon />
                </Button>
              </div>
              <Button
                type="adminSubmit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={() => getLogRowsAsync(false, true)}
                sx={{ mt: 1, mb: 2, mr: 2 }}
              >
                View All
              </Button>
              {logRows.length > 0 && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="Logs Table">
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell align="right">Last Name</TableCell>
                        <TableCell align="right">IP Address</TableCell>
                        <TableCell align="right">Source</TableCell>
                        <TableCell align="right">Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logRows.map((row) => (
                        <TableRow key={row.firstname}>
                          <TableCell component="th" scope="row">
                            {row.firstname}
                          </TableCell>
                          <TableCell align="right">{row.lastname}</TableCell>
                          <TableCell align="right">{row.ipaddress}</TableCell>
                          <TableCell align="right">{row.source}</TableCell>
                          <TableCell align="right">
                            <Moment format="YYYY/MM/DD HH:mm:ss">
                              {row.logintime * 1000}
                            </Moment>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography component="h3" variant="h7" sx={{ marginTop: "1rem" }}>
              Activity
              <IconButton variant="text" onClick={() => handleClick("zoom")}>
                {state.settings.find((item) => item.id === "zoom").open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "zoom").open}
              timeout="auto"
              unmountOnExit
            >
              <Typography
                component="p"
                variant="h6"
                sx={{ marginBottom: "0.5rem" }}
              >
                Currently: {activityLink}
              </Typography>
              <Typography component="h3" variant="h6">
                Change Activity Link
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="zoomLink"
                label="Activity Link"
                name="zoomLink"
                value={zoomLink}
                onChange={(e) => handleZoomChange(e)}
                autoComplete="link"
                autoFocus
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    borderRadius: "15px",
                    "& input": { paddingLeft: 2 },
                    "& .MuiFilledInput-input:autofill": {
                      boxShadow: 0,
                      borderRadius: 0,
                    },
                  },
                }}
              />
              <Button
                type="adminSubmit"
                fullWidth
                variant="contained"
                disabled={!validZoomLink}
                endIcon={<ArrowForwardIcon />}
                onClick={ZoomLinkChange}
                sx={{ mt: 1, mb: 2 }}
              >
                Change Link
              </Button>
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h3"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Add Management
              <IconButton variant="text" onClick={() => handleClick("email")}>
                {state.settings.find((item) => item.id === "email").open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "email").open}
              timeout="auto"
              unmountOnExit
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: "1rem" }}
              >
                <Grid item xs>
                  <Typography component="h3" variant="h6">
                    Add Admin By Email
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="adminEmail"
                    label="Admin Email Address"
                    name="adminEmail"
                    value={adminEmail}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    autoComplete="playeremail"
                    autoFocus
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "15px",
                        "& input": { paddingLeft: 2 },
                        "& .MuiFilledInput-input:autofill": {
                          boxShadow: 0,
                          borderRadius: 0,
                        },
                      },
                    }}
                  />
                  <Button
                    type="adminSubmit"
                    fullWidth
                    variant="contained"
                    disabled={!adminEmail.length > 0}
                    endIcon={<ArrowForwardIcon />}
                    onClick={(e) =>
                      addUserType(e, "admin_set_admin", adminEmail)
                    }
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Add Admin
                  </Button>
                </Grid>
                <Grid item xs sx={{ marginLeft: "1rem" }}>
                  <Typography component="h3" variant="h6">
                    Add Faculty By Email
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="facultyEmail"
                    label="Faculty Email Address"
                    name="facultyEmail"
                    value={facultyEmail}
                    onChange={(e) => setFacultyEmail(e.target.value)}
                    autoComplete="playeremail"
                    autoFocus
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "15px",
                        "& input": { paddingLeft: 2 },
                        "& .MuiFilledInput-input:autofill": {
                          boxShadow: 0,
                          borderRadius: 0,
                        },
                      },
                    }}
                  />{" "}
                  <Button
                    type="facultySubmit"
                    fullWidth
                    variant="contained"
                    disabled={!facultyEmail.length > 0}
                    endIcon={<ArrowForwardIcon />}
                    onClick={(e) =>
                      addUserType(e, "admin_set_faculty", facultyEmail)
                    }
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Add Faculty
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h3"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Password Management
              <IconButton
                variant="text"
                onClick={() => handleClick("password")}
              >
                {state.settings.find((item) => item.id === "password").open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "password").open}
              timeout="auto"
              unmountOnExit
            >
              <Typography>Send User Password Reset</Typography>
              <div className="flex flex-row flex-wrap gap-4">
                <TextField
                  margin="normal"
                  id="passwordemail"
                  label="Email"
                  required
                  name="passwordemail"
                  className="flex-auto"
                  value={emailPassword}
                  onChange={(e) => setEmailPassword(e.target.value)}
                  autoComplete="playeremail"
                  autoFocus
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      borderRadius: "15px",
                      "& input": { paddingLeft: 2 },
                      "& .MuiFilledInput-input:autofill": {
                        boxShadow: 0,
                        borderRadius: 0,
                      },
                    },
                  }}
                />
                <Button
                  className=""
                  type="adminSubmit"
                  variant="contained"
                  onClick={sendPasswordReset}
                  sx={{ mt: 1, mb: 2, mr: 2 }}
                >
                  <ArrowForwardIcon />
                </Button>
              </div>
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              component="h3"
              variant="h7"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Faculty/Admin Management{" "}
              <IconButton
                variant="text"
                onClick={() => handleClick("management")}
              >
                {state.settings.find((item) => item.id === "management")
                  .open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </IconButton>
            </Typography>
            <Collapse
              in={state.settings.find((item) => item.id === "management").open}
              timeout="auto"
              unmountOnExit
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="Admin Table">
                  {rows && (
                    <TableBody>
                      {(rowsPerPage > 0
                        ? rows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : rows
                      ).map((row) => (
                        <TableRow key={row.email}>
                          <TableCell
                            component="th"
                            scope="row"
                          >
                            <Tooltip title={row.account_id}>
                            <div onClick={() => handleCopyClick(row.account_id)}>
                                {row.name}
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <div onClick={() => handleCopyClick(row.id)}>
                            {row.id}
                            </div>
                          </TableCell>
                          <TableCell style={{}} align="right">
                            {row.email}
                          </TableCell>
                          <TableCell style={{}} align="center">
                            {row.status ? "Admin" : "Faculty"}
                          </TableCell>
                          <TableCell style={{}} align="right">
                            <Typography
                              component={Button}
                              variant="contained"
                              color="text.primary"
                              sx={{ textDecoration: "none" }}
                              onClick={(e) =>
                                handleUserPromoteDemote(
                                  e,
                                  row.status,
                                  row.email
                                )
                              }
                            >
                              {row.status ? "Demote" : "Promote"}
                            </Typography>
                          </TableCell>
                          <TableCell style={{}} align="right">
                            <Typography
                              component={Button}
                              variant="contained"
                              color="text.primary"
                              onClick={(e) =>
                                handleUserRemove(e, row.status, row.email)
                              }
                              sx={{ textDecoration: "none" }}
                            >
                              X
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Collapse>
            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "text.secondary", align: "center", fontWeight: 600 }}
            >
              Want to change the banner?{" "}
              {
                <Typography
                  component={Link}
                  to={`/mod${loc}`}
                  variant="contained"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Change Banner
                </Typography>
              }
            </Typography>
          </Box>
        </Box>
        <Button
          type="logout"
          fullWidth
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={ClearSession}
          sx={{ mt: 1, mb: 2 }}
        >
          Logout
        </Button>
      </Container>
      <Collapse in={!open}>
        <Snackbar open={err != "" && !open} autoHideDuration={6000}>
          <Alert
            severity={err == "" ? "error" : err}
            sx={{ width: "100%" }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {mess}
          </Alert>
        </Snackbar>
      </Collapse>
    </Box>
  );
}
